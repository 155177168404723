import { gql } from "@apollo/client";
import { Alignment, Classes, DialogProps } from "@blueprintjs/core";
import { DOCS_LINKS, KernelSize } from "@hex/common";
import React, { useCallback, useRef } from "react";

import {
  HexButton,
  HexDialog,
  HexDivider,
  HexSwitch,
} from "../../hex-components/index.js";
import { DocsLink } from "../common/DocsLink.js";

import { useGetKernelSizesForPickerQuery } from "./KernelSizePicker.generated.js";
import { useUpdateUserConfirmedPaidComputeMutation } from "./PaidComputeConfirmationDialog.generated.js";

export interface PaidComputeConfirmationDialogProps {
  isOpen: DialogProps["isOpen"];
  kernelSize: KernelSize;
  onClose: DialogProps["onClose"];
  onConfirm: () => void;
}

gql`
  mutation UpdateUserConfirmedPaidCompute($confirmed: Boolean!) {
    updateUserConfirmedPaidCompute(confirmed: $confirmed) {
      id
      confirmedPaidCompute
    }
  }
`;

export const PaidComputeConfirmationDialog: React.ComponentType<PaidComputeConfirmationDialogProps> =
  React.memo(function PaidComputeConfirmationDialog({
    isOpen,
    kernelSize,
    onClose,
    onConfirm,
  }) {
    const confirm = useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        onConfirm();
        onClose?.(event);
      },
      [onConfirm, onClose],
    );

    const { data: kernelSizesData } = useGetKernelSizesForPickerQuery({});
    const userConfirmedPaidComputeForever = useRef<boolean>(false);
    const [confirmPaidComputeForever] =
      useUpdateUserConfirmedPaidComputeMutation();
    const handleConfirmPaidComputeChange = useCallback(() => {
      void confirmPaidComputeForever({
        variables: { confirmed: !userConfirmedPaidComputeForever.current },
      });
      userConfirmedPaidComputeForever.current =
        !userConfirmedPaidComputeForever.current;
    }, [confirmPaidComputeForever, userConfirmedPaidComputeForever]);

    const kernelSizeDetails = kernelSizesData?.getKernelSizesV2?.options?.find(
      (option) => option.id === kernelSize,
    );

    const kernelPrice =
      kernelSizeDetails && kernelSizeDetails.requiresPayment
        ? kernelSizeDetails.hourlyCostHumanReadable
        : undefined;

    const gpuVariant = kernelSizeDetails && kernelSizeDetails?.gpuLimit > 0;

    return (
      <HexDialog
        canEscapeKeyClose={false}
        isCloseButtonShown={true}
        isOpen={isOpen}
        style={{ width: "450px", paddingBottom: "15px" }}
        title="Continue with advanced compute?"
        onClose={onClose}
      >
        <div className={Classes.DIALOG_BODY}>
          The <b>{kernelSizeDetails?.humanName} compute profile</b> is billed
          per-minute at <b>{kernelPrice}</b>. This will apply to all notebook,
          app, and scheduled runs for this project.{" "}
          <DocsLink to={DOCS_LINKS.ComputeProfile}>Learn more.</DocsLink>
          {gpuVariant ? (
            <div
              css={`
                padding-top: 18px;
              `}
            >
              Your first V100 GPU per day may take up to 10 minutes to start.
              You will{" "}
              <span
                css={`
                  text-decoration: underline;
                `}
              >
                not
              </span>{" "}
              be charged for this startup time.
            </div>
          ) : null}
        </div>
        <HexDivider />
        <div
          className={Classes.DIALOG_FOOTER}
          css={`
            display: flex;
            justify-content: space-between;
            align-items: center;
            &&&&.${Classes.DIALOG_FOOTER} {
              padding: 10px 10px 0px 10px;
            }
          `}
        >
          <div>
            <HexSwitch
              alignIndicator={Alignment.LEFT}
              checked={userConfirmedPaidComputeForever.current}
              label="Don't ask again"
              onChange={handleConfirmPaidComputeChange}
            />
          </div>
          <div
            css={`
              display: flex;
              gap: 10px;
            `}
          >
            <HexButton onClick={onClose}>Cancel</HexButton>
            <HexButton intent="primary" onClick={confirm}>
              Confirm
            </HexButton>
          </div>
        </div>
      </HexDialog>
    );
  });
