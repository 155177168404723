import { gql } from "@apollo/client";
import { Intent, PopoverInteractionKind } from "@blueprintjs/core";
import { KernelSize, OrgRole } from "@hex/common";
import filesize from "filesize";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { KernelSizeConfigV2 } from "../../generated/graphqlTypes.js";
import { HexCleanLink, HexTooltip } from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import { useToggleState } from "../../hooks/useToggleState.js";
import { ORG_ID } from "../../orgs.js";
import { Routes } from "../../route/routes.js";
import { ContactAnAdmin } from "../common/ContactAnAdmin.js";
import { Picker } from "../common/Picker";
import { FeatureGatePill } from "../feature-gate/FeatureGatePill";

import {
  useGetKernelSizesForPickerQuery,
  useGetOrgAllowPaidComputeQuery,
  useOrgAllowPaidComputeUpdatedSubscription,
  useUserConfirmedPaidComputeQuery,
} from "./KernelSizePicker.generated";
import { PaidComputeConfirmationDialog } from "./PaidComputeConfirmationDialog.js";

gql`
  query GetKernelSizesForPicker {
    getKernelSizesV2 {
      options {
        id
        humanName
        memoryLimit
        cpuLimit
        disabled
        disabledReason
        gpuLimit
        requiresPayment
        hourlyCostHumanReadable
      }
      default
      isFreeTrial
    }
  }

  query GetOrgAllowPaidCompute($orgId: OrgId!) {
    orgById(orgId: $orgId) {
      id
      allowPaidCompute
      computeSpendDetails {
        paidComputeDisabledDueToSpendLimit
      }
    }
  }
`;

gql`
  query UserConfirmedPaidCompute {
    me {
      id
      confirmedPaidCompute
    }
  }
`;

gql`
  subscription OrgAllowPaidComputeUpdated($orgId: OrgId!) {
    orgAllowPaidComputeUpdated(orgId: $orgId) {
      id
      allowPaidCompute
    }
  }
`;

const Description = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

interface KernelSizePickerProps {
  currentKernelSize: KernelSize | null;
  onSelect: (newKernelSize: KernelSize) => void;
  disabled?: boolean;
  hideLabel?: boolean; // defaults to false
  smallPicker?: boolean; // defaults to true
  isSignedEmbeddedProject?: boolean; // defaults to false
}

export const KernelSizePicker: React.FunctionComponent<
  KernelSizePickerProps
> = ({
  currentKernelSize,
  disabled,
  hideLabel = false,
  isSignedEmbeddedProject = false,
  onSelect,
  smallPicker = true,
}) => {
  const [
    paidComputeConfirmationDialogOpen,
    ,
    {
      setFalse: closePaidComputeConfirmationDialog,
      setTrue: openPaidComputeConfirmationDialog,
    },
  ] = useToggleState(false);
  const [pendingKernelSize, setPendingKernelSize] = useState<KernelSize | null>(
    null,
  );

  const handleConfirmPaidCompute = useCallback(() => {
    if (pendingKernelSize) {
      onSelect(pendingKernelSize);
      closePaidComputeConfirmationDialog();
    }
  }, [onSelect, pendingKernelSize, closePaidComputeConfirmationDialog]);

  const { data: userConfirmedPaidComputeData } =
    useUserConfirmedPaidComputeQuery({});
  const userConfirmedPaidComputeForever =
    userConfirmedPaidComputeData?.me?.confirmedPaidCompute ?? false;

  const {
    data: kernelSizesData,
    error: error_,
    loading: kernelSizesLoading,
    refetch,
  } = useGetKernelSizesForPickerQuery({});

  const onSelectInternal = useCallback(
    (newKernelSize: KernelSize) => {
      const newKernelSizeConfig =
        kernelSizesData?.getKernelSizesV2?.options.find(
          (config) => config.id === newKernelSize,
        );
      if (
        newKernelSizeConfig?.requiresPayment &&
        !userConfirmedPaidComputeForever
      ) {
        setPendingKernelSize(newKernelSize);
        openPaidComputeConfirmationDialog();
      } else {
        onSelect(newKernelSize);
      }
    },
    [
      onSelect,
      openPaidComputeConfirmationDialog,
      userConfirmedPaidComputeForever,
      kernelSizesData?.getKernelSizesV2?.options,
    ],
  );

  const { data: allowPaidCompute } = useGetOrgAllowPaidComputeQuery({
    variables: { orgId: ORG_ID },
  });

  useOrgAllowPaidComputeUpdatedSubscription({
    variables: { orgId: ORG_ID },
  });

  const orgAllowPaidCompute =
    allowPaidCompute?.orgById.allowPaidCompute ?? false;

  useEffect(() => {
    void refetch();
  }, [
    // ensures that we refetch kernel details immediately if this option is changed via subscription
    orgAllowPaidCompute,
    refetch,
  ]);

  const maybeDefaultKernelSizeResult = KernelSize.validate(
    kernelSizesData?.getKernelSizesV2?.default,
  );

  const defaultSize = maybeDefaultKernelSizeResult.success
    ? maybeDefaultKernelSizeResult.value
    : null;

  const kernelSizesOrEmpty = kernelSizesData?.getKernelSizesV2?.options ?? [];

  const currentUser = useCurrentUser();
  const userIsAdmin = currentUser?.orgRole === OrgRole.ADMIN;
  const orgName = currentUser?.org.displayName ?? "your organization";

  const getTooltipIfKernelSizeDisabled = (
    kernelSize: KernelSizeConfigV2,
  ): false | JSX.Element => {
    if (kernelSize.disabled && !disabled) {
      if (isSignedEmbeddedProject && kernelSize.requiresPayment) {
        return (
          <HexTooltip content="Advanced compute profiles are disabled for signed embedded projects">
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (kernelSize.disabledReason === "NO_USER_PERMISSION") {
        return (
          <HexTooltip
            content={
              <>
                Advanced compute is restricted to certain user groups.{" "}
                {userIsAdmin ? (
                  <>
                    <HexCleanLink
                      target="_blank"
                      to={Routes.SETTINGS.getUrl({
                        subView: "compute",
                      })}
                    >
                      Manage compute settings
                    </HexCleanLink>
                    .
                  </>
                ) : (
                  <>
                    <ContactAnAdmin text="Contact an admin" /> to get access.
                  </>
                )}
              </>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (kernelSize.disabledReason === "DISABLED_BY_HEX") {
        return (
          <HexTooltip
            content={
              <>
                Advanced compute is disabled for your organization.{" "}
                {userIsAdmin ? (
                  <>
                    Please contact{" "}
                    <HexCleanLink target="_blank" to="mailto:support@hex.tech">
                      support@hex.tech
                    </HexCleanLink>{" "}
                    to request access.
                  </>
                ) : (
                  <>
                    <ContactAnAdmin text="Contact your admin" /> to request
                    access.
                  </>
                )}
              </>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (kernelSize.disabledReason === "MUST_UPGRADE_PLAN") {
        return (
          <HexTooltip
            content={
              <>
                Advanced compute is available on the Team plan.{" "}
                {userIsAdmin ? (
                  <HexCleanLink
                    target="_blank"
                    to={Routes.SETTINGS.getUrl({
                      subView: "tiers",
                    })}
                  >
                    Upgrade now for access.
                  </HexCleanLink>
                ) : (
                  <>
                    {" "}
                    <ContactAnAdmin text="Contact an admin" /> to upgrade.
                  </>
                )}
              </>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (kernelSize.disabledReason === "ORG_IN_TRIAL") {
        return (
          <HexTooltip
            content={
              <>Advanced compute is not available during your free trial.</>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (kernelSize.disabledReason === "ORG_IS_OVER_SPEND_LIMIT") {
        return (
          <HexTooltip
            content={
              <>
                Advanced compute spend limit has been reached for {orgName}.{" "}
                {userIsAdmin ? (
                  <>
                    Update it in{" "}
                    <HexCleanLink
                      target="_blank"
                      to={Routes.SETTINGS.getUrl({
                        subView: "compute",
                      })}
                    >
                      Compute settings
                    </HexCleanLink>
                    .
                  </>
                ) : (
                  <>
                    <ContactAnAdmin text="Contact an admin" /> for help.
                  </>
                )}
              </>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      } else if (kernelSize.disabledReason === "PAID_COMPUTE_DISABLED_BY_ORG") {
        return (
          <HexTooltip
            content={
              <>
                Advanced compute profiles are disabled for {orgName}.{" "}
                {userIsAdmin ? (
                  <>
                    Enable them in{" "}
                    <HexCleanLink
                      target="_blank"
                      to={Routes.SETTINGS.getUrl({
                        subView: "compute",
                      })}
                    >
                      Compute settings
                    </HexCleanLink>
                    .
                  </>
                ) : (
                  <>
                    <ContactAnAdmin text="Contact your admin" /> to get them
                    enabled.
                  </>
                )}
              </>
            }
            interactionKind={PopoverInteractionKind.HOVER}
          >
            <FeatureGatePill />
          </HexTooltip>
        );
      }
    }

    // don't return a tooltip if the kernel size is not disabled
    return false;
  };

  const error = !!error_ || kernelSizesOrEmpty.length === 0;

  return (
    <>
      <Picker
        disabled={disabled}
        error={error}
        items={[...kernelSizesOrEmpty]
          // sort first by GPUs and then Memory
          .sort(
            (a, b) => b.gpuLimit - a.gpuLimit || b.memoryLimit - a.memoryLimit,
          )
          .map((kernelSize) => ({
            ...kernelSize,
            disabled: kernelSize.disabled
              ? true
              : isSignedEmbeddedProject && kernelSize.requiresPayment,
          }))
          .map((kernelSize) => ({
            title: kernelSize.requiresPayment
              ? `${kernelSize.humanName} ${kernelSize.hourlyCostHumanReadable ? `(${kernelSize.hourlyCostHumanReadable})` : ""}` // show price if available for kernel size
              : kernelSize.humanName,
            disabled: kernelSize.disabled,
            selectedItemIntent: kernelSize.hourlyCostHumanReadable
              ? Intent.WARNING
              : undefined, // show with Intent.WARNING when selected only if kernel size costs money
            rightElement: getTooltipIfKernelSizeDisabled(kernelSize),
            description: (
              <Description>
                <div>{filesize(kernelSize.memoryLimit)} memory</div>
                <div>
                  {kernelSize.cpuLimit === 1
                    ? `${kernelSize.cpuLimit} CPU`
                    : `${kernelSize.cpuLimit} CPUs`}
                </div>
                {kernelSize.gpuLimit > 0 ? (
                  <div>
                    {kernelSize.gpuLimit === 1
                      ? `${kernelSize.gpuLimit} GPU`
                      : `${kernelSize.gpuLimit} GPUs`}
                  </div>
                ) : null}
              </Description>
            ),
            key: kernelSize.id,
          }))}
        label={!hideLabel ? "Compute profile" : ""}
        loading={kernelSizesLoading}
        selectedItem={currentKernelSize ?? defaultSize}
        smallPicker={smallPicker}
        onSelect={onSelectInternal}
      />
      {KernelSize.guard(pendingKernelSize) && (
        <PaidComputeConfirmationDialog
          isOpen={paidComputeConfirmationDialogOpen}
          kernelSize={pendingKernelSize}
          onClose={closePaidComputeConfirmationDialog}
          onConfirm={handleConfirmPaidCompute}
        />
      )}
    </>
  );
};
