import { ApolloError, gql } from "@apollo/client";
import { Classes, InputGroupProps2 } from "@blueprintjs/core";
import { ItemListPredicate } from "@blueprintjs/select";
import {
  GroupId,
  IntrinsicGroupId,
  UserId,
  assertNever,
  groupByDiscriminate,
} from "@hex/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useDebounce } from "use-debounce";

import { HexSuggest2 } from "../../hex-components/HexSuggest";
import { AddUserIcon } from "../icons/CustomIcons";
import { popoverProps } from "../schedule/recipients/shared";

import {
  GroupSelectRenderingFragment,
  IntrinsicGroupSelectRenderingFragment,
  UserSelectRenderingFragment,
} from "./UserGroupSelect2.generated.js";
import {
  UserGroupSelectRenderingItem,
  useUserGroupSelectItemRenderer,
  useUserGroupSelectListRenderer,
} from "./UserGroupSelect2.js";

gql`
  query UserGroupSelectSearch(
    $searchString: String!
    $orgId: OrgId!
    $searchUsers: Boolean!
    $searchGroups: Boolean!
    $searchIntrinsicGroups: Boolean!
    $userPageSize: Int!
    $groupPageSize: Int!
    $orgRoleFilter: [OrgRole!]
  ) {
    searchOrgUsers(
      orgId: $orgId
      searchString: $searchString
      pageSize: $userPageSize
      orgRoleFilter: $orgRoleFilter
    ) @include(if: $searchUsers) {
      ...UserSelectRenderingFragment
    }
    searchOrgGroups(
      orgId: $orgId
      searchString: $searchString
      pageSize: $groupPageSize
    ) @include(if: $searchGroups) {
      ...GroupSelectRenderingFragment
    }
    intrinsicGroups(orgId: $orgId) @include(if: $searchIntrinsicGroups) {
      ...IntrinsicGroupSelectRenderingFragment
    }
  }
`;

export type UserGroupSelectStub =
  | UserSelectRenderingFragment
  | GroupSelectRenderingFragment
  | IntrinsicGroupSelectRenderingFragment;

export const SearchResultGroupLabel = styled.div<{ $spaceAbove?: boolean }>`
  display: flex;
  gap: 4px;
  align-items: center;
  margin: 6px 0px 7px 7px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-weight: ${({ theme }) => theme.fontWeight.SEMI_BOLD};
  font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
  text-transform: uppercase;

  ${({ $spaceAbove }) => $spaceAbove && `margin-top: 12px`}
`;

// we clear the input after user selection
// so basically just a noop
const itemValueRenderer = (_item: UserGroupSelectStub): string => "";

export interface UserGroupSearchResponse {
  data: UserGroupSelectStub[];
  error: ApolloError | undefined;
  loading: boolean | undefined;
}

interface UserGroupListSelectBaseProps {
  placeholder?: string;
  minimal?: boolean;
  clearOnSelect?: boolean;
  closeOnSelect?: boolean;
  onSearchCallback: (
    maxNumberOfGroups: number,
    maxNumberOfUsers: number,
    searchString: string,
  ) => Promise<UserGroupSearchResponse>;
}

interface UserProps {
  selectedUserIds: readonly UserId[];
  setSelectedUserIds: (
    setStateCallback: (prevUsers: readonly UserId[]) => UserId[],
  ) => void;
}

interface NoUserProps {
  selectedUserIds?: undefined;
  setSelectedUserIds?: undefined;
}

interface GroupProps {
  selectedGroupIds: readonly GroupId[];
  onAddGroupId: (groupId: GroupId) => void;
  /**
   * If specified, restrict the possible groups to select to any group not in this value.
   * If not defined, allow any group.
   */
  excludedGroupIds?: readonly GroupId[];
}

interface NoGroupProps {
  selectedGroupIds?: undefined;
  onAddGroupId?: undefined;
  excludedGroupIds?: undefined;
}

interface IntrinsicGroupProps {
  selectedIntrinsicGroupIds: readonly IntrinsicGroupId[];
  onAddIntrinsicGroupId: (intrinsicGroupId: IntrinsicGroupId) => void;
  /**
   * If specified, restrict the possible intrinsic groups to select to any group not in this value.
   * If not defined, allow any intrinsic group.
   */
  excludedIntrinsicGroupIds?: readonly IntrinsicGroupId[];
}

interface NoIntrinsicGroupProps {
  selectedIntrinsicGroupIds?: undefined;
  onAddIntrinsicGroupId?: undefined;
  excludedIntrinsicGroupIds?: undefined;
}

export type UserGroupListSelectProps = UserGroupListSelectBaseProps &
  (
    | (UserProps & GroupProps & IntrinsicGroupProps)
    | (UserProps & GroupProps & NoIntrinsicGroupProps)
    | (UserProps & NoGroupProps & IntrinsicGroupProps)
    | (UserProps & NoGroupProps & NoIntrinsicGroupProps)
    | (NoUserProps & GroupProps & IntrinsicGroupProps)
    | (NoUserProps & GroupProps & NoIntrinsicGroupProps)
    | (NoUserProps & NoGroupProps & IntrinsicGroupProps)
  );

export const UserGroupSelect: React.ComponentType<UserGroupListSelectProps> =
  React.memo(function UserGroupSelect({
    clearOnSelect = true,
    closeOnSelect,
    excludedGroupIds,
    excludedIntrinsicGroupIds,
    minimal,
    onAddGroupId,
    onAddIntrinsicGroupId,
    onSearchCallback,
    placeholder,
    selectedGroupIds,
    selectedIntrinsicGroupIds,
    selectedUserIds,
    setSelectedUserIds,
  }) {
    const maxNumberOfUsers = 5;
    // includes intrinsic groups
    const maxNumberOfGroups = 5;

    const inputProps: InputGroupProps2 = useMemo(
      () => ({
        leftIcon: minimal ? undefined : (
          <AddUserIcon className={Classes.TAG_INPUT_ICON} />
        ),
        placeholder:
          placeholder ??
          ((onAddGroupId || onAddIntrinsicGroupId) && setSelectedUserIds
            ? "Add users or groups..."
            : onAddGroupId || onAddIntrinsicGroupId
              ? "Add groups..."
              : "Add users..."),
      }),
      [
        minimal,
        placeholder,
        onAddGroupId,
        onAddIntrinsicGroupId,
        setSelectedUserIds,
      ],
    );

    const selectedUserIdsSet = useMemo(
      () => new Set(selectedUserIds),
      [selectedUserIds],
    );
    const selectedGroupIdsSet = useMemo(
      () => new Set(selectedGroupIds),
      [selectedGroupIds],
    );
    const selectedIntrinsicGroupIdsSet = useMemo(
      () => new Set(selectedIntrinsicGroupIds),
      [selectedIntrinsicGroupIds],
    );

    const [searchString, setSearchString] = useState("");
    const [searchLoading, setSearchLoading] = useState<boolean>();
    const [searchError, setSearchError] = useState<ApolloError>();
    const [mutableSearchData, setMutableSearchData] = useState<
      UserGroupSelectStub[]
    >([]);

    // don't rerun the query too frequently
    const [debouncedSearchString] = useDebounce(searchString, 200, {
      maxWait: 600,
    });

    const onSearchChange = useCallback((newSearch: string) => {
      setSearchString(newSearch);
    }, []);

    const fetchData = useCallback(async () => {
      const { data, error, loading } = await onSearchCallback(
        maxNumberOfGroups,
        maxNumberOfUsers,
        debouncedSearchString,
      );
      setSearchError(error);
      setSearchLoading(loading);
      setMutableSearchData(data);
    }, [onSearchCallback, debouncedSearchString]);

    useEffect(() => {
      void fetchData();
    }, [debouncedSearchString, fetchData]);

    const onItemSelect = useCallback(
      (
        item: UserGroupSelectStub,
        event: React.SyntheticEvent<HTMLElement, Event> | undefined,
      ) => {
        // Prevent selection from closing any other menus
        event?.stopPropagation();
        if (item.__typename === "User") {
          setSelectedUserIds?.((prevUserIds) =>
            prevUserIds.filter((id) => id !== item.userId).concat(item.userId),
          );
        } else if (item.__typename === "Group") {
          onAddGroupId?.(item.groupId);
        } else if (item.__typename === "IntrinsicGroup") {
          onAddIntrinsicGroupId?.(item.intrinsicGroupId);
        } else {
          assertNever(item, (item as { __typename: string }).__typename);
        }
        if (clearOnSelect) {
          setSearchString("");
        }
      },
      [clearOnSelect, onAddGroupId, setSelectedUserIds, onAddIntrinsicGroupId],
    );

    const itemListPredicate: ItemListPredicate<UserGroupSelectStub> =
      useCallback(
        (query, items) => {
          const groupedItems = groupByDiscriminate(items, "__typename");

          const usersToRender =
            groupedItems.User?.filter(
              (u) => !selectedUserIdsSet.has(u.userId),
            ) ?? [];

          const intrinsicGroupIdsToNotAllow = new Set(
            excludedIntrinsicGroupIds,
          );

          const intrinsicGroupsToRender =
            groupedItems.IntrinsicGroup?.filter(
              (ig) =>
                !selectedIntrinsicGroupIdsSet.has(ig.intrinsicGroupId) &&
                !intrinsicGroupIdsToNotAllow.has(ig.intrinsicGroupId) &&
                (query.length === 0 ||
                  ig.intrinsicGroupName
                    .toLowerCase()
                    .split(" ")
                    .some((word) => word.startsWith(query.toLowerCase()))),
            ) ?? [];

          const groupIdsToNotAllow = new Set(excludedGroupIds);

          const groupsToRender =
            groupedItems.Group?.filter(
              (g) =>
                !selectedGroupIdsSet.has(g.groupId) &&
                !groupIdsToNotAllow.has(g.groupId),
            )
              // count intrinsic groups against the group total
              .slice(
                0,
                intrinsicGroupsToRender.length > 0
                  ? maxNumberOfGroups - intrinsicGroupsToRender.length
                  : undefined,
              ) ?? [];

          return [
            ...intrinsicGroupsToRender,
            ...groupsToRender,
            ...usersToRender,
          ];
        },
        [
          excludedIntrinsicGroupIds,
          excludedGroupIds,
          selectedUserIdsSet,
          selectedIntrinsicGroupIdsSet,
          selectedGroupIdsSet,
        ],
      );

    const itemRenderer =
      useUserGroupSelectItemRenderer<UserGroupSelectRenderingItem>({});

    const itemListRenderer =
      useUserGroupSelectListRenderer<UserGroupSelectRenderingItem>({
        loading: searchLoading,
        error: searchError != null,
      });

    return (
      <HexSuggest2<UserGroupSelectRenderingItem>
        closeOnSelect={closeOnSelect}
        inputProps={inputProps}
        inputValueRenderer={itemValueRenderer}
        itemListPredicate={itemListPredicate}
        itemListRenderer={itemListRenderer}
        itemRenderer={itemRenderer}
        items={mutableSearchData}
        popoverProps={popoverProps}
        query={searchString}
        onItemSelect={onItemSelect}
        onQueryChange={onSearchChange}
      />
    );
  });
